import { Column, Row } from "@react-tiny-grid/core"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

const Box = styled.div`
  @media screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export default function UseCasesSection() {
  return (
    <>
      <h3 className="text-primary">Some use case stories</h3> <br />
      <h2>
        For specialists around the world, we make digital forensic acquisition
        of websites{" "}
        <span className="text-secondary">
          simple, reliable, fast and automatic.
        </span>
      </h2>
      <br /> <br />
      <Row breakpoints={[576]}>
        <Column>
          <Box>
            <StaticImage
              src="../../images/legal-practitioners.png"
              loading="eager"
              width={96}
              height={96}
              alt="Legal practitioners"
            />
            <br />
            <h3>Legal practitioners</h3> <br />
            <p>
              Collect court case proof related to{" "}
              <span className="text-secondary">
                social media reputational damages
              </span>
              or{" "}
              <span className="text-secondary">
                intellectual property infringement.
              </span>
            </p>
          </Box>
        </Column>
        <Column>
          <Box>
            <StaticImage
              src="../../images/journalism.png"
              loading="eager"
              width={96}
              height={96}
              alt="Journalism"
            />
            <br />
            <h3>Journalism</h3> <br />
            <p>
              Protect your groundbreaking case evidence from being treated as{" "}
              <span className="text-secondary">fake news.</span>
            </p>
          </Box>
        </Column>
        <Column>
          <Box>
            <StaticImage
              src="../../images/due-diligence.png"
              loading="eager"
              width={96}
              height={96}
              alt="Due diligence"
            />
            <br />
            <h3>Due diligence</h3> <br />
            <p>
              Helps in{" "}
              <span className="text-secondary">
                background checks for professional hiring clearance
              </span>
              or investigating{" "}
              <span className="text-secondary">
                breach of T&amp;C or NDA Collect
              </span>
            </p>
          </Box>
        </Column>
      </Row>
      <br />
      <Row breakpoints={[576]}>
        <Column>
          <Box>
            <StaticImage
              src="../../images/law-enforcement.png"
              loading="eager"
              width={96}
              height={96}
              alt="Law enforcement"
            />
            <br />
            <h3>Law enforcement</h3> <br />
            <p>
              In case of{" "}
              <span className="text-secondary">police investigations</span> or
              for any other reason that requires as supporting evidence the
              online content acquisition.
            </p>
          </Box>
        </Column>
        <Column>
          <Box>
            <StaticImage
              src="../../images/private-investigators.png"
              loading="eager"
              width={130}
              height={96}
              alt="Private investigators"
            />
            <br />
            <h3>Private investigators</h3> <br />
            <p>
              Collect evidence related to{" "}
              <span className="text-secondary">
                intellectual property infringement
              </span>{" "}
              cases.
            </p>
          </Box>
        </Column>
        <Column>
          <Box>
            <StaticImage
              src="../../images/OSINT-cybersecurity.png"
              loading="eager"
              width={96}
              height={96}
              alt="OSINT &amp; Cybersecurity"
            />
            <br />
            <h3>OSINT &amp; Cybersecurity</h3> <br />
            <p>
              Investigating{" "}
              <span class="text-secondary">
                system events, fraud or suspicious online activity, deep fake
                videos
              </span>
            </p>
          </Box>
        </Column>
      </Row>
      <br /> <br /> <br />
    </>
  )
}
