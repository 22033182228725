import { Column, Row } from "@react-tiny-grid/core"
import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const Input = styled.input`
  border: none;
  background-color: #add3d059;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  margin: 5px 0;
`

const TextArea = styled.textarea`
  border: none;
  background-color: #add3d059;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  margin: 5px 0;
`

const Button = styled(Link)`
  background-color: ${({ color }) => color};
  border-radius: 12px;
  padding: 12px 25px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

export default function ContactSection() {
  return (
    <>
      <h3 className="text-primary">Ready to get started?</h3>
      <Row>
        <Column>
          <h2>Try Eviquire today!</h2>

          <br />
          <p className="text">
            Create an account instantly and start acquiring evidence. You can
            also contact us to design a custom pachage for your business.
          </p>

          <br />
          <Button
            color="#3BC6BD"
            to="/contact-sales"
            style={{ display: "block", margin: "0 auto", width: "150px" }}
          >
            Get Started
          </Button>
        </Column>

        <Column>
          <h2>Contact Us</h2>

          <Input placeholder="Enter your name" />
          <Input placeholder="Enter your email" />
          <Input placeholder="Select topic" />
          <TextArea placeholder="Add your message" />

          <br />
          <br />
          <Button
            color="#ADD3D0"
            to="/contact-sales"
            style={{
              display: "block",
              margin: "0 auto",
              width: "150px",
              color: "#000",
            }}
          >
            Send
          </Button>
        </Column>
      </Row>
    </>
  )
}
