import { Column, Row } from "@react-tiny-grid/core"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > * {
    margin: 0 10px;
  }
`

const ShadowBox = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 25px;
  background-color: ${({ color }) => color};
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const Button = styled(Link)`
  background-color: ${({ color }) => color};
  border-radius: 12px;
  padding: 12px 25px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

export default function ProductsSection() {
  return (
    <>
      <h3 className="text-primary">Our product</h3> <br />
      <h2>Versatile deplyment and easy-to-use solution</h2> <br /> <br />
      <h3 className="text-center">EVIQUIRE Desktop</h3> <br />
      <p className="text">
        Desktop version of our software compatible with Windows and Linux.
        Allows unlimited online content acquisition. All data is saved on
        customer servers.
      </p>
      <br />
      <Icons>
        <StaticImage
          src="../../images/windows.png"
          loading="eager"
          width={75}
          alt="Windows Icon"
        />
        <StaticImage
          src="../../images/ubuntu.png"
          loading="eager"
          width={75}
          alt="Ubuntu Icon"
        />
      </Icons>
      <br />
      <ShadowBox color="#153250">
        <h2 className="text-white">Why you should choose our product ?</h2>{" "}
        <br />
        <Row>
          <Column>
            <h3 className="text-white">Best Value for Money</h3> <br />
            <p className="text-white text-justify">
              Our product offers the most advantageous combination of cost,
              quality and sustainability to meet customer requirements
            </p>
          </Column>

          <Column>
            <h3 className="text-white">Reliable</h3> <br />
            <p className="text-white text-justify">
              Our product is build to offer you the quickest way to acquire
              online content without anyone's help. Everytime. Anytime.
            </p>
          </Column>

          <Column>
            <h3 className="text-white">Innovative</h3> <br />
            <p className="text-white text-justify">
              Our product offers Blockchain timestamping for an extra layer of
              security.
            </p>
          </Column>
        </Row>
      </ShadowBox>
      <br /> <br />
      <ShadowBox color="#E5E5E540">
        <h2 className="text-center">
          Try now forensically acquiring your first website with our reliable
          and smart solution.
        </h2>
        <br />

        <Buttons>
          <Button color="#153250" to="/contact-sales">
            Contact sales
          </Button>
          <Button color="#3BC6BD" to="/get-started">
            Get Started
          </Button>
        </Buttons>
      </ShadowBox>
      <br /> <br /> <br />
    </>
  )
}
