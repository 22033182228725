import React from "react"
import styled from "styled-components"

const Testimonial = styled.div`
  display: flex;
  padding: 25px;
`

const TestimonialIcon = styled.div`
  width: 125px;
  height: 125px;
  border-radius: 50%;
  background-color: #ccc;
`

const TestimonialText = styled.div`
  padding: 25px;
  height: 125px;
  display: flex;
  margin-left: 25px;
  align-items: center;
  justify-content: center;
  background-color: #cccccc;
  text-align: center;
`

export default function TestimonialsSection() {
  return (
    <>
      <h2>Testimonials</h2> <br />
      <p className="text">
        Be part of our product success and tell us what you like, what we should
        improve and what are new features you would like to enjoy.
      </p>
      <Testimonial>
        <TestimonialIcon />
        <TestimonialText>
          “ The software is easy to use and the acquisition package contains all
          the elements needed to close the case. ” ( Dave R, Lawyer Inc.)
        </TestimonialText>
      </Testimonial>
      <br /> <br /> <br />
    </>
  )
}
