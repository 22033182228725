import { Column, Row } from "@react-tiny-grid/core"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export default function HeroSection() {
  return (
    <>
      <h1>
        WELECOME TO <span className="text-primary">EVIQUIRE</span>
      </h1>
      <br />
      <h2>
        The digital forensic acquisition of online content tool for smart users.
      </h2>
      <br />
      <Row>
        <Column>
          <p className="text">
            EVIQUIRE is the perfect solution{" "}
            <span className="text-secondary text-underline">
              to help you colect online evidence meeting forensic standards.
            </span>
          </p>
          <br />
          <p className="text">
            All collected artifacts are organised under a dedicated case files
            and an acquisition report is created to help you have all the
            information summarised. As a bonus, we timestamp in blockchain the
            evidence package.
          </p>
          <br />
          <p className="text">
            Our software is developed to fullfill ISO IEC 27037 2012 standard on
            identification, collection, acquisition and preservation of digital
            evidence.
          </p>
        </Column>

        <Column>
          <StaticImage
            src="../../images/product-image.png"
            loading="eager"
            formats={["auto", "webp", "avif"]}
            alt="Product Image"
          />
        </Column>
      </Row>
      <br /> <br /> <br />
    </>
  )
}
