import * as React from "react"
import ContactSection from "../components/HomePageElements/ContactSection"
import HeroSection from "../components/HomePageElements/HeroSection"
import ProductsSection from "../components/HomePageElements/ProductsSection"
import TestimonialsSection from "../components/HomePageElements/TestimonialsSection"
import UseCasesSection from "../components/HomePageElements/UseCasesSection"
import WhyEviquireSection from "../components/HomePageElements/WhyEviquireSection"
import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <HeroSection />
    <ProductsSection />
    <UseCasesSection />
    <WhyEviquireSection />
    <TestimonialsSection />
    <ContactSection />
  </Layout>
)

export default IndexPage
