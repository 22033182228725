import { Column, Row } from "@react-tiny-grid/core"
import React from "react"

const whyUs = [
  [
    {
      title: "Confortable browser experience",
      text: "No need for preparation work. Acquistion is done by a customized Chrome based browser.",
    },
    {
      title: "Preserve chain of custody",
      text: "The software follows a corect and consistent procedure to ensure quality of evidence.",
    },
    {
      title: "Easy to use case manager",
      text: "Organise all the information into cases to increase your productivity and simplify retrievel of historical files.",
    },
  ],
  [
    {
      title: "Automated artifact collection",
      text: "Enjoy court ready evidence packages automatically put together by the software.",
    },
    {
      title: "Audio/Video recording",
      text: "Conduct in a transparent manner the evidence acquisition. Record step by step the acquisition of the webpages.",
    },
    {
      title: "Blockchain timestamping",
      text: "Timestamp the acquisition package to offer additional security that the evidence has not been modified.",
    },
  ],
  [
    {
      title: "Acquisition report generated",
      text: "Automatic report generated with the summary of the acquistion session and all the artifacts collected.",
    },
    {
      title: "Network packets captured",
      text: "Real-time capture of network data is downloaded and added to the evidence package. ",
    },
    {
      title: "Page and Element Autoscroll",
      text: "Simplify your work by letting the software do the autoscroll when acquiring long webpages.",
    },
  ],
]

export default function WhyEviquireSection() {
  return (
    <>
      <h3 className="text-primary">Why EVIQUIRE ?</h3> <br />
      <h2>A technology-first approach to online content acquisition</h2>
      <br /> <br />
      {whyUs.map(rows => {
        return (
          <Row breakpoints={[576]}>
            {rows.map(column => (
              <Column>
                <h3 className="text-secondary">{column.title}</h3> <br />
                <p>{column.text}</p>
              </Column>
            ))}
          </Row>
        )
      })}
      <br /> <br /> <br />
    </>
  )
}
